import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import {
  Typography, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Grid, Button, TableFooter, TablePagination, Switch,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import cx from 'classnames';
import { Form } from 'react-final-form';
import { TextField } from 'mui-rff';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import PieChart from '../../components/Charts/PieChart';
import Chart from '../../components/Charts/Chart';
import {
  getAdvertiser, updateAdvertiser, refreshAdvertiserDetail, getDataCharts,
} from '../../state/modules/advertiser/actions';
import { capitalizeFirstLetter } from '../../utils/utils';
import TablePaginationActions from '../../components/TablePaginationActions/TablePaginationActions';
import CustomPeriod from '../../components/DatePickers/Period';

const useStyles = makeStyles({
  paper: {
    margin: '30px',
    padding: '20px',
  },
  table: {
    maxWidth: '100%',
  },
  tableCellHead: {
    fontWeight: 'bold',
    minWidth: '150px',
  },
  statusSuccess: {
    color: '#62b700',
    fontWeight: 'bold',
  },
  statusFailed: {
    color: '#e91e63',
    fontWeight: 'bold',
  },
  button: {
    marginRight: '15px',
  },
  titleGrid: {
    paddingLeft: '15px',
  },
  resize: {
    fontSize: 36,
  },
  inputDisabled: {
    color: '#000',
    borderWidth: '0',
  },
  lableDisabled: {
    display: 'none',
  },
  notchedOutline: {
    borderWidth: '0',
  },
  period: {
    marginBottom: '50px',
    height: '50px',
  },
});

const periods = [
  { label: 'last 24 hours', value: 'day' },
  { label: 'last 7 days', value: 'week' },
  { label: 'Last 30 days', value: 'month' },
];

export default function AdvertiserDetail(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [period, setPeriod] = useState('day');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(moment().format());
  const { advertiser, advertiserDetails, total } = useSelector(state => state.advertiser);
  const { lineChart = [], pieChart = [] } = useSelector(state => state.advertiser.charts);

  useEffect(() => {
    const { match: { params: { id = null } } } = props;
    if (id) {
      dispatch(getAdvertiser(id, {
        rowsPerPage, page, period, startDate, endDate,
      }));
      dispatch(getDataCharts(id, { period }));
    }
  }, []);

  const displayStatus = status => (
    <Typography className={cx((status === 'ok' ? classes.statusSuccess : classes.statusFailed))}>
      {capitalizeFirstLetter(status)}
    </Typography>
  );

  const submit = (values) => {
    dispatch(updateAdvertiser(values, advertiser._id, {
      rowsPerPage, page, period, startDate, endDate,
    }))
      .then(() => setIsEdit(false));
  };

  const refresh = () => {
    dispatch(refreshAdvertiserDetail(advertiser._id, {
      rowsPerPage, page, period, startDate, endDate,
    }))
      .then(() => dispatch(getDataCharts(advertiser._id, { period })));
  };

  const handleChangeRowsPerPage = (event) => {
    const { value } = event.target;
    setRowsPerPage(value);
    dispatch(getAdvertiser(advertiser._id, {
      rowsPerPage: value, page, period, startDate, endDate,
    }));
  };

  const handleChangePage = (event, value) => {
    setPage(value);
    dispatch(getAdvertiser(advertiser._id, {
      rowsPerPage, page: value, period, startDate, endDate,
    }));
  };

  const handleChangePeriod = (value) => {
    setPeriod(value);
    dispatch(getDataCharts(advertiser._id, { period: value, startDate, endDate }));
    dispatch(getAdvertiser(advertiser._id, {
      rowsPerPage, page, period: value, startDate, endDate,
    }));
  };

  const renderPeriodButtons = () => periods.map((item) => {
    const color = item.value === period ? 'primary' : 'default';
    return (<Button key={item.value} variant="contained" color={color} onClick={() => handleChangePeriod(item.value)}>{item.label}</Button>);
  });

  const handleEnabled = () => {
    dispatch(updateAdvertiser({ isEnabled: !advertiser.isEnabled }, advertiser._id,
      {
        rowsPerPage, page, period, startDate, endDate,
      }));
  };
  return (
    <Paper elevation={3} className={classes.paper}>
      <Grid item xs={12} container direction="row" justify="flex-start" alignItems="center">
        <Grid item xs={1}>
          <Button>
            <ArrowBackIosIcon onClick={() => props.history.push('/')} />
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Form
            initialValues={{ name: advertiser.name, endpoint: advertiser.endpoint }}
            onSubmit={submit}
            render={({ handleSubmit, values, form }) => (
              <form
                style={{
                  width: '100%',
                }}
                onSubmit={(event) => {
                  event.preventDefault();
                  handleSubmit(values);
                }}
              >
                <Grid item xs={12} container direction="row" justify="flex-start" alignItems="center">
                  <Grid item xs={3} container direction="row" justify="space-between" alignItems="center">
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      disabled={!isEdit}
                      InputProps={{
                        classes: {
                          input: classes.resize,
                          disabled: classes.inputDisabled,
                          notchedOutline: !isEdit && classes.notchedOutline,
                        },
                      }}
                      id="name"
                      label="Advertiser Name"
                      name="name"
                      type="text"
                    />
                  </Grid>
                  <Grid className={classes.titleGrid} item xs={3} container direction="row" justify="space-between" alignItems="center">
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      disabled={!isEdit}
                      InputProps={{
                        classes: {
                          input: classes.resize,
                          disabled: classes.inputDisabled,
                          notchedOutline: !isEdit && classes.notchedOutline,
                        },
                      }}
                      name="endpoint"
                      label="Endpoint"
                      type="endpoint"
                      id="endpoint"
                    />
                  </Grid>
                  <Grid className={classes.titleGrid} item xs={3} container direction="row" justify="flex-start" alignItems="center">
                    <Switch
                      checked={!!advertiser.isEnabled}
                      onChange={handleEnabled}
                      color="primary"
                      name="isEnabled"
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                    <Typography variant="h6">{advertiser.isEnabled ? 'Active' : 'Inactive'}</Typography>
                  </Grid>
                  {isEdit ? (
                    <Grid item xs={3} container direction="row" justify="flex-end" alignItems="center">
                      <Button
                        className={classes.button}
                        variant="contained"
                        onClick={() => {
                          form.reset();
                          setIsEdit(false);
                        }}
                      >
                        Cancel
                      </Button>
                      <Button color="primary" variant="contained" type="submit">Update</Button>
                    </Grid>
                  ) : (
                    <Grid item xs={3} container direction="row" justify="flex-end" alignItems="center">
                      <Button className={classes.button} onClick={() => setIsEdit(true)} variant="contained" color="primary">Edit</Button>
                      <Button variant="contained" color="primary" onClick={refresh}>Refresh</Button>
                    </Grid>
                  )
                  }
                </Grid>
              </form>
            )}
          />
        </Grid>
      </Grid>
      <Grid item md={12} className={classes.period} container direction="row" justify="space-between" alignItems="flex-end">
        {renderPeriodButtons()}
        <CustomPeriod
          period={period}
          endDate={endDate}
          startDate={startDate}
          setEndDate={setEndDate}
          setStartDate={setStartDate}
          setPeriod={setPeriod}
          handleChangePeriod={handleChangePeriod}
        />
      </Grid>
      <Grid item xs={12} container direction="row" justify="flex-start" alignItems="center">
        <Grid item xs={8} container direction="row" justify="flex-start" alignItems="center">
          <Chart details={lineChart} />
        </Grid>
        <Grid item xs={4} container direction="row" justify="flex-start" alignItems="center">
          <PieChart details={pieChart} />
        </Grid>
      </Grid>
      <Grid item xs={12} container direction="row" justify="flex-start" alignItems="center">
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCellHead}>Status</TableCell>
                <TableCell className={classes.tableCellHead}>Date and Time</TableCell>
                <TableCell className={classes.tableCellHead}>Error</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {advertiserDetails.length ? advertiserDetails.map((row, i) => (
                <TableRow key={i}>
                  <TableCell component="th" scope="row">
                    {displayStatus(row.status)}
                  </TableCell>
                  <TableCell>{moment(row.createdAt).format('MM-DD-Y H:mm:ss')}</TableCell>
                  <TableCell className={classes.errorCell}>
                    {row.error}
                  </TableCell>
                </TableRow>
              ))
                : (
                  <TableRow>
                    <TableCell colSpan={7} component="th" scope="row">
                      <Typography align="center">data is empty</Typography>
                    </TableCell>
                  </TableRow>
                )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  colSpan={7}
                  count={+total}
                  rowsPerPage={+rowsPerPage}
                  page={+page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Grid>
    </Paper>
  );
}

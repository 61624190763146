export const getHeaders = () => {
  const authToken = `${localStorage.getItem('auth_token')}`;
  return {
    JWTAuthorization: authToken || '',
  };
};

export const getHost = () => `${window.location.protocol}//${window.location.hostname}`;

export const capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1);

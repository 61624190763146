import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DialogContent, DialogContentText } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import {
  getAdvertisers, deleteAdvertiser,
} from '../../state/modules/advertiser/actions';

export default function Modal({
  deleteId, closeModal, content, title,
}) {
  const dispatch = useDispatch();
  const handleClose = () => {
    closeModal('');
  };

  const handleAgree = () => {
    dispatch(deleteAdvertiser(deleteId))
      .then(() => dispatch(getAdvertisers()));
    closeModal('');
  };

  return (
    <Dialog
      fullWidth
      open={!!deleteId}
      onClose={handleClose}
      maxWidth="xs"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
      {content && (
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Disagree
        </Button>
        <Button onClick={handleAgree} variant="contained" color="primary" autoFocus>
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
}

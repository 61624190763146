import React, { Fragment } from 'react';
import { Provider } from 'react-redux';
import { Route, BrowserRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ToastContainer } from 'react-toastify';
import Root from './pages/Root';
import store from './state/store';

import 'react-toastify/dist/ReactToastify.css';

export default function App() {
  return (
    <Fragment>
      <Provider store={store}>
        <BrowserRouter>
          <CssBaseline />
          <Route path="/" component={Root} />
        </BrowserRouter>
      </Provider>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
    </Fragment>
  );
}

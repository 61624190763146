import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoute = ({ component: Component, monitoring, ...rest }) => {
  const { isAuthenticated } = useSelector(state => state.user);
  return (
    <Route
      {...rest}
      render={props => (isAuthenticated
        ? (
          <Component {...props} monitoring={monitoring} />
        ) : (
          <Redirect to={{ pathname: '/sign-in', state: { from: props.location } }} />
        ))
      }
    />
  );
};

export default PrivateRoute;

import React, { Fragment, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {
  Typography, Grid, Button, Collapse, IconButton, Avatar,
} from '@material-ui/core';
import { TextField } from 'mui-rff';
import { Form } from 'react-final-form';
import cx from 'classnames';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';

import {
  addAdvertiser, getAdvertisers, refreshAdvertiserDetail,
} from '../../state/modules/advertiser/actions';
import { capitalizeFirstLetter } from '../../utils/utils';
import Modal from '../../components/Modals/DeleteModal';

const useStyles = makeStyles(theme => ({
  table: {
    width: '100%',
  },
  paper: {
    margin: '20px',
    padding: '30px',
  },
  grid: {
    padding: '20px',
  },
  tableCellHead: {
    fontWeight: 'bold',
    minWidth: '150px',
  },
  button: {
    marginRight: '15px',
  },
  statusSuccess: {
    color: '#62b700',
    fontWeight: 'bold',
  },
  statusFailed: {
    color: '#e91e63',
    fontWeight: 'bold',
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  green: {
    backgroundColor: '#58e500',
    color: '#58e500',
  },
  red: {
    backgroundColor: '#e50000',
    color: '#e50000',
  },
}));

export default function AdvertiserList({ monitoring }) {
  const [checked, setChecked] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const { advertisers } = useSelector(state => state.advertiser);
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    dispatch(getAdvertisers(monitoring));
  }, [monitoring]);

  const displayStatus = status => (
    <Typography className={cx((status === 'ok' ? classes.statusSuccess : classes.statusFailed))}>
      {capitalizeFirstLetter(status)}
    </Typography>
  );

  const submit = (values, form) => {
    dispatch(addAdvertiser({ ...values, monitoring }))
      .then(() => dispatch(getAdvertisers(monitoring)))
      .then(() => {
        setChecked(false);
        form.reset();
      });
  };

  const refresh = (id) => {
    dispatch(refreshAdvertiserDetail(id))
      .then(() => dispatch(getAdvertisers(monitoring)));
  };

  const removeAdvetiser = (id) => {
    setDeleteId(id);
  };

  const getTitle = () => (monitoring === 'podcast' ? 'Podcasts EndPoint List' : 'Advertisers List');

  return (
    <Fragment>
      <Paper className={classes.paper} elevation={3}>
        <Grid className={classes.grid} item xs={12} container direction="row" justify="space-between" alignItems="center">
          <Grid className={classes.grid} item xs={8}>
            <Typography variant="h2">
              {getTitle()}
            </Typography>
          </Grid>
          <Grid className={classes.grid} item xs={3} container direction="row" justify="flex-end" alignItems="center">
            {!checked
              ? <Button className={classes.button} color="primary" variant="contained" onClick={() => setChecked(true)}>Add Advertiser Endpoint</Button>
              : null}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Collapse in={checked}>
            <Form
              onSubmit={submit}
              render={({ handleSubmit, form }) => (
                <form
                  onSubmit={handleSubmit}
                >
                  <Grid item xs={12} container direction="column" justify="flex-start" alignItems="center">
                    <Grid item xs={3} container direction="row" justify="space-between" alignItems="center">
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        label="Advertiser Name"
                        name="name"
                        type="text"
                      />
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="endpoint"
                        label="Endpoint"
                        type="endpoint"
                        id="endpoint"
                      />
                    </Grid>
                    <Grid item xs={3} container direction="row" justify="space-between" alignItems="center">
                      <Button
                        className={classes.button}
                        variant="contained"
                        onClick={() => {
                          form.reset();
                          setChecked(false);
                        }}
                      >
                        Cancel
                      </Button>
                      <Button color="primary" variant="contained" type="submit">Add</Button>
                    </Grid>
                  </Grid>
                </form>
              )}
            />
          </Collapse>
        </Grid>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCellHead}>Name</TableCell>
              <TableCell className={classes.tableCellHead} align="left">Status</TableCell>
              <TableCell className={classes.tableCellHead} align="left">Last checked</TableCell>
              <TableCell className={classes.tableCellHead} align="left">Error</TableCell>
              <TableCell className={classes.tableCellHead} align="left">Enabled</TableCell>
              <TableCell className={classes.tableCellHead} align="left">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {advertisers.length ? advertisers.map(row => (
              <TableRow key={row._id}>
                <TableCell component="th" scope="row">
                  <Link to={`/advertiser/${row._id}`}>{row.name}</Link>
                </TableCell>
                {!row.status ? (
                  <TableCell colSpan={3} component="th" scope="row">
                    <Button variant="contained" color="primary" onClick={() => refresh(row._id)}>Refresh</Button>
                  </TableCell>
                ) : (
                  <Fragment>
                    <TableCell align="left">{displayStatus(row.status)}</TableCell>
                    <TableCell align="left">{moment(row.date).format('MM-DD-Y H:mm:ss')}</TableCell>
                    <TableCell align="left">{row.error}</TableCell>
                  </Fragment>
                )
                }
                <TableCell align="center">
                  <Avatar className={cx(classes.small,
                    row.isEnabled ? classes.green : classes.red)}
                  />
                </TableCell>
                <TableCell>
                  <IconButton aria-label="delete" className={classes.margin} onClick={() => removeAdvetiser(row._id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))
              : (
                <TableRow>
                  <TableCell colSpan={7} component="th" scope="row">
                    <Typography align="center">data is empty</Typography>
                  </TableCell>
                </TableRow>
              )}
          </TableBody>
        </Table>
      </Paper>
      <Modal
        deleteId={deleteId}
        closeModal={setDeleteId}
        title="Are you sure?"
      />
    </Fragment>
  );
}

import React, { useEffect } from 'react';
import { Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../state/modules/user/actions';
import PublicRoute from '../components/PublicRoute/PublicRoute';
import PrivateRoute from '../components/PrivateRoute/PrivateRoute';
import LoginPage from './Login/Login';
import Menu from './menu';
import AdvertiserList from './Advertiser/AdvertiserList';
import AdvertiserDetails from './Advertiser/AdvertiserDetail';

export default function Root() {
  const { isAuthenticated } = useSelector(state => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUser());
  }, []);

  if (isAuthenticated === null) {
    return <div className="loading">Loading&#8230;</div>;
  }
  return (
    <Switch>
      <PublicRoute path="/sign-in" component={LoginPage} />
      <Menu>
        <Switch>
          <PrivateRoute path="/advertiser/:id" component={AdvertiserDetails} />
          <PrivateRoute path="/podcast" monitoring="podcast" component={AdvertiserList} />
          <PrivateRoute path="/" monitoring="HaP" component={AdvertiserList} />
        </Switch>
      </Menu>
    </Switch>
  );
}

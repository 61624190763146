import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import { Grid } from '@material-ui/core';

import { logout } from '../state/modules/user/actions';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  menuBtn: {
    marginLeft: 20,
    color: '#fff',
  },
}));

export default function ButtonAppBar(props) {
  const classes = useStyles();
  const { loading } = useSelector(state => state.advertiser);
  const dispatch = useDispatch();

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <Grid item xs={12} container direction="raw" justify="flex-start" alignItems="center">
            <Button component={Link} disabled={loading} to="/" className={classes.menuBtn}>Host And Post Monitor</Button>
            <Button component={Link} disabled={loading} to="/podcast" className={classes.menuBtn}>Podcast</Button>
          </Grid>
          <Button color="inherit" onClick={() => dispatch(logout())}>Logout</Button>
        </Toolbar>
      </AppBar>
      {props.children}
    </div>
  );
}

import 'date-fns';
import React, { useState, Fragment } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  DatePicker,
} from '@material-ui/pickers';
import moment from 'moment';
import { Button, Collapse, Grid } from '@material-ui/core';

export default function Period({
  period, handleChangePeriod, startDate, endDate, setEndDate, setStartDate, setPeriod,
}) {
  const [disabled, setDisable] = useState(true);
  const [checked, setChecked] = useState(false);

  const isDisabled = (error) => {
    if (error || !endDate || !startDate) {
      return setDisable(true);
    }
    return setDisable(false);
  };

  const handlePeriod = () => {
    setChecked(true);
    setPeriod('custom');
  };

  return (
    <Fragment>
      <Button variant="contained" color={period === 'custom' ? 'primary' : 'default'} onClick={handlePeriod}>Custom</Button>
      <Collapse in={(period === 'custom' && checked)}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
            <DatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              label="Start Date"
              autoOk
              value={startDate}
              onError={isDisabled}
              maxDate={endDate}
              onChange={value => setStartDate(moment(value).startOf('day').toISOString())}
            />
            <DatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              label="End Date"
              autoOk
              onError={isDisabled}
              value={endDate}
              minDate={startDate}
              disableFuture
              onChange={value => setEndDate(moment(value).endOf('day').toISOString())}
            />
            <Button variant="contained" disabled={disabled} color="primary" onClick={() => handleChangePeriod('custom', { startDate, endDate })}>Submit</Button>
          </Grid>
        </MuiPickersUtilsProvider>
      </Collapse>
    </Fragment>
  );
}

import axios from 'axios';
import { toast } from 'react-toastify';
import * as actionTypes from './types';
import { getHeaders } from '../../../utils/utils';

export function getUser() {
  return async (dispatch) => {
    dispatch({ type: actionTypes.USER_LOADING });
    try {
      const headers = getHeaders();
      const res = await axios.get('/api/user', { headers });
      if (res.data.status === 401) {
        throw res.data;
      } else {
        dispatch({ type: actionTypes.GET_USER_SUCCESS, payload: res.data });
      }
    } catch (error) {
      console.error(error);
      dispatch({ type: actionTypes.GET_USER_FAIL, payload: error.response });
    }
  };
}

export function login(formData) {
  return async (dispatch) => {
    dispatch({ type: actionTypes.LOGIN });
    try {
      const response = await axios.post('/api/auth/login', formData);
      localStorage.setItem('auth_token', response.data.token);
      dispatch({ type: actionTypes.LOGIN_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message.message);
      dispatch({ type: actionTypes.LOGIN_FAIL, payload: error.response });
    }
  };
}

export function logout() {
  return async (dispatch) => {
    localStorage.setItem('auth_token', '');
    try {
      await axios.post('/api/logout');
      localStorage.removeItem('auth_token');
      dispatch({ type: actionTypes.LOGOUT_SUCCESS });
    } catch (error) {
      console.error(error);
      // dispatch({ type: actionTypes.LOGOUT_FAIL, payload: error });
    }
  };
}

import * as actionTypes from './types';

let initialState = {
  advertisers: [],
  advertiser: {},
  advertiserDetails: [],
  total: 0,
  charts: {},
  loadining: false,
};

function advertiserReducer(state = initialState, action = {}) {
  switch (action.type) {
    case actionTypes.GET_ADVERTISERS_SUCCESS:
      return { ...state, advertisers: action.payload };
    case actionTypes.GET_ADVERTISER_SUCCESS:
    case actionTypes.UPDATE_ADVERTISER_SUCCESS:
      return {
        ...state,
        advertiser: action.payload,
        advertiserDetails: action.payload.advertiserDetails.docs,
        total: action.payload.advertiserDetails.total,
      };
    case actionTypes.GET_CHARTS_SUCCESS:
      return { ...state, charts: action.payload };
    case actionTypes.ADVERTISER_LOADING:
      return { ...state, loading: true };
    case actionTypes.ADVERTISER_LOADED:
      return { ...state, loadining: false };
    default:
      return state;
  }
}

export default advertiserReducer;

import React from 'react';
import moment from 'moment';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';
import { capitalizeFirstLetter } from '../../utils/utils';

export default function Chart({ details }) {
  return (
    <LineChart
      width={800}
      height={300}
      data={details}
      margin={{
        top: 5, right: 30, left: 20, bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis
        dataKey="time"
        reversed
        name="Time"
        type="category"
        domain={['0', 'auto']}
        tickFormatter={unixTime => moment(unixTime).format('HH:mm Do')}
      />
      <YAxis
        type="category"
        dataKey="status"
        tickFormatter={capitalizeFirstLetter}
        padding={{ top: 25, bottom: 25 }}
      />
      <Tooltip labelFormatter={value => moment(value).format('HH:mm Do')} />
      <Legend />
      <Line type="monotone" dataKey="status" stroke="#8884d8" activeDot={{ r: 8 }} />
    </LineChart>
  );
}

import axios from 'axios';
import { toast } from 'react-toastify';
import * as actionTypes from './types';
import { getHeaders } from '../../../utils/utils';

export function addAdvertiser(formData) {
  return async () => {
    try {
      const headers = getHeaders();
      await axios.post('/api/advertiser', { ...formData }, { headers });
      toast.success('Advertiser Added!');
    } catch (error) {
      console.error(error);
    }
  };
}

export function getAdvertisers(monitoring) {
  return async (dispatch) => {
    try {
      dispatch({ type: actionTypes.ADVERTISER_LOADING });
      const headers = getHeaders();
      const res = await axios.get('/api/advertiser', { headers, params: { monitoring } });
      dispatch({ type: actionTypes.GET_ADVERTISERS_SUCCESS, payload: res.data });
      dispatch({ type: actionTypes.ADVERTISER_LOADED });
    } catch (error) {
      console.log(error);
      dispatch({ type: actionTypes.GET_ADVERTISERS_FAIL, payload: error.response });
      dispatch({ type: actionTypes.ADVERTISER_LOADED });
    }
  };
}

export function getAdvertiser(id, params) {
  return async (dispatch) => {
    try {
      dispatch({ type: actionTypes.ADVERTISER_LOADING });
      const headers = getHeaders();
      const res = await axios.get(`/api/advertiser/${id}`, { params, headers });
      dispatch({ type: actionTypes.GET_ADVERTISER_SUCCESS, payload: res.data });
      dispatch({ type: actionTypes.ADVERTISER_LOADED });
    } catch (error) {
      dispatch({ type: actionTypes.ADVERTISER_LOADED });
      console.log(error);
    }
  };
}

export function updateAdvertiser(data, id, params) {
  return async (dispatch) => {
    try {
      dispatch({ type: actionTypes.ADVERTISER_LOADING });
      const headers = getHeaders();
      const res = await axios.put(`/api/advertiser/${id}`, data, { params, headers });
      toast.success('Advertiser Updated!');
      dispatch({ type: actionTypes.UPDATE_ADVERTISER_SUCCESS, payload: res.data });
      dispatch({ type: actionTypes.ADVERTISER_LOADED });
    } catch (error) {
      console.log(error);
      dispatch({ type: actionTypes.ADVERTISER_LOADED });
    }
  };
}

export function refreshAdvertiserDetail(id, params) {
  return async (dispatch) => {
    try {
      dispatch({ type: actionTypes.ADVERTISER_LOADING });
      const headers = getHeaders();
      const res = await axios.get(`/api/advertiser/${id}/refresh`, { params, headers });
      dispatch({ type: actionTypes.GET_ADVERTISER_SUCCESS, payload: res.data });
      dispatch({ type: actionTypes.ADVERTISER_LOADED });
    } catch (error) {
      console.log(error);
      dispatch({ type: actionTypes.ADVERTISER_LOADED });
    }
  };
}

export function getDataCharts(id, params) {
  return async (dispatch) => {
    try {
      const headers = getHeaders();
      const res = await axios.get(`/api/advertiser/${id}/charts`, { params, headers });
      dispatch({ type: actionTypes.GET_CHARTS_SUCCESS, payload: res.data });
    } catch (error) {
      console.log(error);
    }
  };
}


export function deleteAdvertiser(id) {
  return async () => {
    try {
      const headers = getHeaders();
      await axios.delete(`/api/advertiser/${id}`, { headers });
    } catch (error) {
      console.log(error);
    }
  };
}

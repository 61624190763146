export const ADD_ADVERTISER = 'ADD_ADVERTISER';
export const ADD_ADVERTISER_SUCCESS = 'ADD_ADVERTISER_SUCCESS';
export const ADD_ADVERTISER_FAIL = 'ADD_ADVERTISER_FAIL';

export const GET_ADVERTISERS = 'GET_ADVERTISERS';
export const GET_ADVERTISERS_SUCCESS = 'GET_ADVERTISERS_SUCCESS';
export const GET_ADVERTISERS_FAIL = 'GET_ADVERTISERS_FAIL';

export const GET_ADVERTISER = 'GET_ADVERTISER';
export const GET_ADVERTISER_SUCCESS = 'GET_ADVERTISER_SUCCESS';
export const GET_ADVERTISER_FAIL = 'GET_ADVERTISER_FAIL';

export const UPDATE_ADVERTISER = 'UPDATE_ADVERTISER';
export const UPDATE_ADVERTISER_SUCCESS = 'UPDATE_ADVERTISER_SUCCESS';
export const UPDATE_ADVERTISER_FAIL = 'UPDATE_ADVERTISER_FAIL';

export const GET_CHARTS = 'GET_CHARTS';
export const GET_CHARTS_SUCCESS = 'GET_CHARTS_SUCCESS';
export const GET_CHARTS_FAIL = 'GET_CHARTS_FAIL';

export const ADVERTISER_LOADING = 'ADVERTISER_LOADING';
export const ADVERTISER_LOADED = 'ADVERTISER_LOADED';
